import { ReactElement } from 'react';

export const WakeboardAndWaterSki = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M48.7998 56.8004C47.6998 56.8004 46.5998 56.3004 45.9998 55.3004L41.8998 49.2004C41.4998 48.6004 40.8998 48.3004 40.1998 48.4004L29.5998 49.3004C26.2998 49.6004 23.0998 47.6004 21.9998 44.4004L18.2998 34.0004C17.2998 31.3004 18.6998 28.4004 21.3998 27.5004L23.5998 26.7004C24.6998 26.3004 25.7998 26.2004 26.8998 26.5004L29.6998 27.1004C30.5998 27.3004 31.4998 27.2004 32.3998 26.8004L37.8998 24.5004C39.3998 23.9004 41.0998 24.5004 41.7998 26.0004C42.1998 26.8004 42.1998 27.6004 41.8998 28.4004C41.5998 29.2004 40.9998 29.8004 40.1998 30.1004L33.9998 32.7004C32.8998 33.2004 31.6998 33.3004 30.4998 33.2004L28.7998 33.0004L31.2998 40.1004C31.4998 40.7004 31.9998 41.0004 32.5998 41.0004L42.6998 40.7004C43.8998 40.7004 44.8998 41.2004 45.5998 42.2004L51.6998 51.5004C52.1998 52.3004 52.3998 53.2004 52.1998 54.1004C51.9998 55.0004 51.3998 55.8004 50.5998 56.2004C49.9998 56.6004 49.3998 56.8004 48.7998 56.8004ZM40.3998 46.9004C41.4998 46.9004 42.5998 47.5004 43.1998 48.4004L47.2998 54.4004C47.8998 55.2004 48.9998 55.5004 49.7998 55.0004C50.1998 54.7004 50.4998 54.3004 50.6998 53.8004C50.7998 53.3004 50.6998 52.8004 50.3998 52.4004L44.1998 43.0004C43.8998 42.5004 43.2998 42.2004 42.5998 42.2004L32.4998 42.5004C31.2998 42.5004 30.0998 41.8004 29.6998 40.6004L27.1998 33.0004C27.0998 32.6004 27.0998 32.1004 27.3998 31.8004C27.6998 31.5004 28.0998 31.3004 28.4998 31.3004L30.5998 31.6004C31.4998 31.7004 32.4998 31.6004 33.3998 31.2004L39.5998 28.6004C39.9998 28.4004 40.2998 28.1004 40.4998 27.7004C40.6998 27.3004 40.5998 26.9004 40.3998 26.5004C39.9998 25.8004 39.1998 25.4004 38.3998 25.7004L32.8998 28.0004C31.7998 28.5004 30.5998 28.6004 29.3998 28.4004L26.5998 27.8004C25.7998 27.6004 24.8998 27.7004 24.0998 28.0004L21.8998 28.8004C19.9998 29.6004 18.9998 31.7004 19.6998 33.5004L23.3998 43.9004C24.2998 46.4004 26.7998 48.0004 29.4998 47.8004L40.0998 46.9004C40.1998 46.9004 40.2998 46.9004 40.3998 46.9004Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M21.2006 25.4C20.4006 25.4 19.7006 25.2 19.0006 24.9C17.7006 24.3 16.8006 23.2 16.3006 21.9C15.8006 20.6 15.9006 19.2 16.5006 17.9C17.1006 16.6 18.1006 15.7 19.4006 15.2C20.7006 14.7 22.1006 14.8 23.4006 15.4C24.7006 16 25.6006 17 26.1006 18.3C26.6006 19.6 26.5006 21 25.9006 22.3C25.3006 23.6 24.3006 24.5 23.0006 25C22.4006 25.3 21.8006 25.4 21.2006 25.4ZM21.2006 16.5C20.8006 16.5 20.4006 16.6 20.0006 16.7C19.0006 17 18.3006 17.7 17.9006 18.6C17.4006 19.5 17.4006 20.5 17.7006 21.4C18.0006 22.3 18.7006 23.1 19.6006 23.5C20.5006 23.9 21.5006 24 22.4006 23.6C23.3006 23.3 24.1006 22.6 24.5006 21.7C24.9006 20.9 25.0006 19.9 24.6006 18.9C24.3006 18 23.6006 17.3 22.8006 16.9C22.3006 16.6 21.7006 16.5 21.2006 16.5Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M56.9012 24.1998C56.5012 24.1998 56.2012 23.8998 56.2012 23.4998C56.2012 23.0998 56.5012 22.6998 56.9012 22.6998L68.9012 21.7998C69.3012 21.7998 69.7012 22.0998 69.7012 22.4998C69.7012 22.8998 69.4012 23.2998 69.0012 23.2998L56.9012 24.1998Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M48.3016 31.5C48.0016 31.5 47.8016 31.3 47.7016 31.1L42.0016 21.9C41.8016 21.6 41.8016 21.3 42.0016 21.1C42.2016 20.9 42.5016 20.7 42.8016 20.8L54.8016 23C55.1016 23 55.3016 23.2 55.4016 23.5C55.5016 23.8 55.4016 24 55.2016 24.2L49.0016 31.2C48.7016 31.4 48.5016 31.5 48.3016 31.5ZM44.1016 22.5L48.4016 29.4L53.1016 24.1L44.1016 22.5Z"
                fill="#0DD1C5"
            />
            <path
                d="M25.5002 61.6998C25.3002 61.6998 25.1002 61.6998 25.0002 61.6998C24.6002 61.5998 24.3002 61.1998 24.4002 60.7998C24.5002 60.3998 24.9002 60.0998 25.3002 60.1998C26.2002 60.2998 34.5002 59.2998 38.5002 58.7998C46.4002 57.7998 56.2002 53.8998 59.0002 52.4998C61.5002 51.1998 64.6002 47.8998 64.6002 47.8998C64.9002 47.5998 65.4002 47.5998 65.7002 47.8998C66.0002 48.1998 66.0002 48.6998 65.7002 48.9998C65.6002 49.0998 62.4002 52.4998 59.7002 53.8998C56.8002 55.3998 46.7002 59.3998 38.7002 60.3998C30.4002 61.2998 26.8002 61.6998 25.5002 61.6998Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M25.3004 65.9998C23.9004 65.9998 22.5004 65.6998 21.3004 64.9998C20.9004 64.7998 20.8004 64.3998 21.0004 63.9998C21.2004 63.5998 21.6004 63.4998 22.0004 63.6998C24.0004 64.6998 26.3004 64.7998 28.3004 63.8998L30.2004 63.0998C32.6004 62.0998 35.3004 62.0998 37.6004 63.2998L38.6004 63.7998C40.6004 64.7998 42.9004 64.7998 45.0004 63.7998L46.1004 63.2998C48.5004 62.1998 51.3004 62.1998 53.7004 63.2998L54.6004 63.6998C56.7004 64.6998 59.1004 64.6998 61.2004 63.5998C63.7004 62.2998 66.8004 62.2998 69.3004 63.5998C69.7004 63.7998 69.8004 64.1998 69.6004 64.5998C69.4004 64.9998 69.0004 65.0998 68.6004 64.8998C66.5004 63.7998 63.9004 63.7998 61.8004 64.8998C59.3004 66.1998 56.4004 66.1998 53.9004 65.0998L53.0004 64.6998C51.0004 63.6998 48.7004 63.6998 46.7004 64.6998L45.6004 65.1998C43.2004 66.3998 40.4004 66.2998 37.9004 65.1998L36.9004 64.6998C35.0004 63.7998 32.7004 63.6998 30.8004 64.5998L28.9004 65.3998C27.7004 65.7998 26.5004 65.9998 25.3004 65.9998Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M41.8011 71.2996C40.5011 71.2996 39.2011 70.9996 37.9011 70.3996L36.9011 69.8996C35.0011 68.9996 32.7011 68.8996 30.8011 69.7996C30.4011 69.9996 30.0011 69.7996 29.8011 69.3996C29.6011 68.9996 29.8011 68.5996 30.2011 68.3996C32.6011 67.3996 35.3011 67.3996 37.6011 68.5996L38.6011 69.0996C40.6011 70.0996 42.9011 70.0996 45.0011 69.0996L46.1011 68.5996C48.5011 67.4996 51.3011 67.4996 53.7011 68.5996L54.6011 68.9996C56.7011 69.9996 59.1011 69.9996 61.2011 68.8996C61.6011 68.6996 62.0011 68.7996 62.2011 69.1996C62.4011 69.5996 62.3011 69.9996 61.9011 70.1996C59.4011 71.4996 56.5011 71.5996 54.0011 70.3996L52.9011 69.9996C50.9011 68.9996 48.6011 68.9996 46.6011 69.9996L45.5011 70.4996C44.4011 70.9996 43.1011 71.2996 41.8011 71.2996Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
