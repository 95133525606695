import { ReactElement } from 'react';

export const HelicopterAndAirplaneTours = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M35.3468 35.9217C31.2742 35.9217 28.4547 32.9978 28.037 28.2987C28.037 27.9855 28.2459 27.6722 28.5592 27.6722C28.8724 27.6722 29.1857 27.881 29.1857 28.1943C29.3946 31.3271 31.1698 34.6686 35.2423 34.6686H42.3432C42.4477 33.8332 42.4477 33.2067 42.3432 32.5801C42.03 30.2828 39.2105 28.5076 36.8087 28.1943L19.3698 26.3147C19.0565 26.3147 18.8477 26.0014 18.8477 25.6881C18.8477 25.3748 19.1609 25.0616 19.5786 25.166L37.0176 27.0456C39.837 27.3589 43.2831 29.5518 43.5963 32.4757C43.7008 33.2067 43.7008 33.8332 43.7008 34.5642C43.7008 34.8775 43.5963 35.2952 43.2831 35.504C43.0742 35.7129 42.6565 35.9217 42.3432 35.9217H35.3468Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M22.9195 24.3302C22.6062 24.3302 22.293 24.017 22.293 23.7037C22.293 23.3904 22.6062 23.0771 22.9195 23.0771H41.8204C42.1337 23.0771 42.447 23.3904 42.447 23.7037C42.447 24.017 42.2381 24.2258 41.8204 24.2258L22.9195 24.3302Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M32.6313 24.2255C32.5269 24.2255 32.4224 24.2255 32.318 24.121L25.5304 20.1529C25.2171 19.9441 25.1127 19.6308 25.3215 19.3175C25.5304 19.0042 25.8437 18.8998 26.1569 19.1087L32.6313 22.9724L39.1056 19.1087C39.4189 18.8998 39.8366 19.0042 39.941 19.3175C40.1499 19.6308 40.0454 20.0485 39.7322 20.1529L32.9446 24.2255C32.8401 24.2255 32.7357 24.2255 32.6313 24.2255Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M25.3219 38.8449C25.0086 38.8449 24.6953 38.5316 24.6953 38.2183C24.6953 37.9051 25.0086 37.5918 25.3219 37.5918H43.1785C43.4918 37.5918 43.9095 37.4874 44.2227 37.3829C44.536 37.2785 44.7449 37.0697 44.8493 36.7564C44.9537 36.4431 45.267 36.2343 45.6847 36.3387C45.998 36.4431 46.2068 36.7564 46.1024 37.1741C45.8935 37.8006 45.3714 38.3228 44.7449 38.5316C44.3272 38.636 43.805 38.7405 43.2829 38.7405L25.3219 38.8449Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M40.7769 38.845C40.4637 38.845 40.1504 38.5317 40.1504 38.2184V35.2945C40.1504 34.9812 40.4637 34.668 40.7769 34.668C41.0902 34.668 41.4035 34.9812 41.4035 35.2945V38.2184C41.5079 38.5317 41.1946 38.845 40.7769 38.845Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M33.5719 38.8448C33.2586 38.8448 32.9453 38.5316 32.9453 38.2183V35.0855C32.9453 34.7723 33.2586 34.459 33.5719 34.459C33.8851 34.459 34.1984 34.7723 34.1984 35.0855V38.2183C34.1984 38.5316 33.9896 38.8448 33.5719 38.8448Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M37.4344 32.5791C35.5547 32.5791 33.9884 31.0128 33.8839 29.1331V27.5667L33.153 26.3136H31.7954L31.1689 27.3579C30.96 27.6712 30.5423 27.6712 30.3335 27.4623C30.1247 27.2535 30.0202 26.8358 30.2291 26.6269L31.0645 25.3738C31.1689 25.165 31.3777 25.0605 31.5866 25.0605H33.5707C33.7795 25.0605 33.9884 25.165 34.0928 25.3738L35.137 27.0446C35.2415 27.149 35.2415 27.2535 35.2415 27.3579V29.1331C35.2415 30.3862 36.2857 31.326 37.5388 31.326H42.9689C43.2822 31.326 43.5954 31.6393 43.5954 31.9526C43.5954 32.2659 43.2822 32.5791 42.9689 32.5791H37.4344Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M18.3251 28.8203C18.2206 28.8203 18.1162 28.8203 18.0118 28.7159C17.8029 28.6115 17.6985 28.1938 17.8029 27.8805L18.7428 26.2097C18.9516 25.8964 18.9516 25.5832 18.7428 25.2699L16.4454 20.4664C16.341 20.1531 16.4454 19.7354 16.7587 19.631C17.072 19.5265 17.4897 19.631 17.5941 19.9442L19.8914 24.7478C20.2047 25.3743 20.2047 26.2097 19.787 26.8363L18.9516 28.5071C18.8472 28.7159 18.6383 28.8203 18.3251 28.8203Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M64.7951 61.0879C64.6906 61.0879 64.5862 61.0879 64.5862 61.0879L50.4889 60.2525C50.3845 60.2525 50.3845 60.2525 50.28 60.2525L42.7614 57.2242C42.5526 57.1197 42.3438 56.9109 42.3438 56.5976V55.1357C42.3438 54.8224 42.657 54.5091 42.9703 54.5091C43.2836 54.5091 43.5968 54.8224 43.5968 55.1357V56.1799L50.6977 58.9994L64.6907 59.7304C65.526 59.8348 66.4659 59.5215 67.0924 58.895C67.719 58.2684 68.1367 57.433 68.1367 56.5976C68.1367 55.7622 67.8234 54.9268 67.0924 54.3003C66.4659 53.6737 65.6305 53.3604 64.6907 53.4649L49.0269 54.3003C48.8181 54.3003 48.6092 54.1958 48.5048 53.987L45.8942 50.2277H43.5968V52.7339C43.5968 53.0472 43.2836 53.3605 42.9703 53.3605C42.657 53.3605 42.3438 53.0472 42.3438 52.7339V49.6012C42.3438 49.2879 42.657 48.9746 42.9703 48.9746H46.2075C46.4163 48.9746 46.6252 49.079 46.7296 49.2879L49.3402 53.0472L64.5862 52.2118C65.8393 52.1074 66.988 52.6295 67.9278 53.4649C68.8676 54.3003 69.2853 55.4489 69.2853 56.702C69.2853 57.9551 68.7632 59.1038 67.9278 59.9392C67.0924 60.6702 65.9437 61.0879 64.7951 61.0879Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M45.9984 55.5529H41.1949C40.8816 55.5529 40.5684 55.2396 40.5684 54.9264C40.5684 54.6131 40.8816 54.2998 41.1949 54.2998H45.9984C46.3117 54.2998 46.625 54.6131 46.625 54.9264C46.625 55.2396 46.3117 55.5529 45.9984 55.5529Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M60.1991 65.5778C59.2592 65.5778 58.4238 64.7424 58.4238 63.8026C58.4238 62.8627 59.2592 62.0273 60.1991 62.0273C61.1389 62.0273 61.9743 62.8627 61.9743 63.8026C61.9743 64.7424 61.1389 65.5778 60.1991 65.5778ZM60.1991 63.2804C59.8858 63.2804 59.6769 63.4893 59.6769 63.8026C59.6769 64.1158 59.8858 64.3247 60.1991 64.3247C60.5123 64.3247 60.7212 64.1158 60.7212 63.8026C60.7212 63.4893 60.5123 63.2804 60.1991 63.2804Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M53.7254 53.9865C53.6209 53.9865 53.5165 53.9865 53.4121 53.8821C53.0988 53.6733 53.0988 53.2556 53.3077 53.0467L56.4404 48.6609C56.5448 48.5564 56.7537 48.452 56.8581 48.3476C57.067 48.3476 57.2758 48.452 57.3802 48.5564L60.1997 51.4803V48.8697C60.1997 48.5564 60.513 48.2432 60.8262 48.2432C61.1395 48.2432 61.4528 48.5564 61.4528 48.8697V53.0467C61.4528 53.2556 61.3484 53.5688 61.0351 53.6733C60.8262 53.7777 60.513 53.6733 60.3041 53.5688L56.9625 50.1228L54.2475 53.8821C54.1431 53.8821 53.9342 53.9865 53.7254 53.9865Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M51.5328 49.9147C51.2195 49.9147 50.9062 49.7059 50.9062 49.2882C50.9062 48.9749 51.1151 48.6616 51.5328 48.6616L64.1682 47.9307C64.4815 47.9307 64.7947 48.1395 64.7947 48.5572C64.7947 48.8705 64.5859 49.1838 64.1682 49.1838L51.5328 49.9147Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M71.3738 57.0158H68.5543C68.241 57.0158 67.9277 56.7025 67.9277 56.3892C67.9277 56.076 68.241 55.7627 68.5543 55.7627H71.3738C71.687 55.7627 72.0003 56.076 72.0003 56.3892C72.0003 56.7025 71.7915 57.0158 71.3738 57.0158Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M70.4332 62.3414C70.1199 62.3414 69.8066 62.0281 69.8066 61.7148V51.69C69.8066 51.3767 70.1199 51.0635 70.4332 51.0635C70.7465 51.0635 71.0597 51.3767 71.0597 51.69V61.7148C71.0597 62.0281 70.7465 62.3414 70.4332 62.3414Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M59.3651 63.6983C59.1563 63.6983 59.0519 63.5938 58.9474 63.4894L55.9191 60.3567C55.7103 60.1478 55.7103 59.939 55.8147 59.6257C55.9191 59.4169 56.128 59.208 56.4412 59.208L60.7227 59.4169C61.0359 59.4169 61.3492 59.7301 61.3492 60.0434V62.5496C61.3492 62.8629 61.0359 63.1761 60.7227 63.1761C60.4094 63.1761 59.9917 63.0717 59.9917 62.654V60.7744L57.9032 60.67L59.7828 62.654C59.9917 62.8629 59.9917 63.2806 59.7828 63.4894C59.6784 63.6983 59.4696 63.6983 59.3651 63.6983Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M18.0124 53.8832C15.1929 53.8832 13 51.5858 13 48.8708C13 46.1557 15.2973 43.8584 18.0124 43.8584C18.7434 43.8584 19.3699 43.9628 20.1009 44.2761C20.4142 44.3805 20.5186 44.7982 20.4142 45.1115C20.3097 45.4248 19.892 45.5292 19.5788 45.4248C19.0566 45.2159 18.5345 45.1115 18.0124 45.1115C15.9239 45.1115 14.2531 46.7823 14.2531 48.8708C14.2531 50.9593 15.9239 52.6301 18.0124 52.6301C19.0566 52.6301 19.9965 52.2124 20.623 51.5858C20.9363 51.377 21.354 51.377 21.5628 51.5858C21.7717 51.7947 21.7717 52.2124 21.5628 52.4212C20.623 53.3611 19.3699 53.8832 18.0124 53.8832Z"
                fill="#0DD1C5"
            />
            <path
                d="M24.1739 53.8825C22.8164 53.8825 21.5633 53.3604 20.6234 52.4205C20.4146 52.2117 20.4146 51.794 20.6234 51.5851C20.8323 51.3763 21.25 51.3763 21.4588 51.5851C22.1898 52.3161 23.1296 52.6294 24.0695 52.6294C25.1137 52.6294 26.3668 51.8984 26.9933 51.2719C27.2022 51.063 27.6199 51.063 27.8287 51.2719C28.0376 51.4807 28.0376 51.8984 27.8287 52.1073C27.2022 53.0471 25.6358 53.8825 24.1739 53.8825Z"
                fill="#0DD1C5"
            />
            <path
                d="M31.1699 53.8832C29.3947 53.8832 28.0371 53.2566 27.2017 52.108C26.9929 51.7947 27.0973 51.4814 27.3062 51.2726C27.6194 51.0637 27.9327 51.1681 28.1416 51.377C28.7681 52.2124 29.8124 52.6301 31.1699 52.6301C33.2584 52.6301 34.9292 50.9593 34.9292 48.8708C34.9292 46.7823 33.2584 45.1115 31.1699 45.1115C30.3345 45.1115 29.4991 45.1115 28.977 45.5292C28.6637 45.738 28.3504 45.6336 28.1416 45.4248C27.9327 45.1115 28.0371 44.7982 28.246 44.5894C29.1858 43.9628 30.23 43.8584 31.2743 43.8584C34.0938 43.8584 36.2867 46.1557 36.2867 48.8708C36.2867 51.5858 33.9893 53.8832 31.1699 53.8832Z"
                fill="#0DD1C5"
            />
            <path
                d="M28.5593 45.7376C28.2461 45.7376 27.9328 45.4244 27.9328 45.1111C27.9328 43.127 26.262 41.5606 24.1735 41.5606C22.2939 41.5606 20.6231 43.0226 20.4142 44.9022C20.4142 45.2155 20.1009 45.5288 19.6832 45.4244C19.37 45.4244 19.0567 45.1111 19.1611 44.6934C19.4744 42.1872 21.5629 40.2031 24.1735 40.2031C26.993 40.2031 29.1859 42.2916 29.1859 45.0067C29.1859 45.4244 28.8726 45.7376 28.5593 45.7376Z"
                fill="#0DD1C5"
            />
            <path
                d="M53.726 37.488C51.533 37.488 49.7578 35.7128 49.7578 33.5199C49.7578 31.327 51.533 29.5518 53.726 29.5518C54.2481 29.5518 54.8746 29.6562 55.2923 29.865C55.6056 29.9695 55.71 30.3872 55.6056 30.7004C55.5012 31.0137 55.0835 31.1181 54.7702 31.0137C54.4569 30.9093 54.0392 30.8049 53.6215 30.8049C52.1596 30.8049 50.9065 32.058 50.9065 33.5199C50.9065 34.9818 52.1596 36.2349 53.6215 36.2349C54.3525 36.2349 54.9791 35.9217 55.5012 35.504C55.71 35.2951 56.1277 35.2951 56.3366 35.504C56.5454 35.7128 56.5454 36.1305 56.3366 36.3394C55.71 37.0703 54.7702 37.488 53.726 37.488Z"
                fill="#0DD1C5"
            />
            <path
                d="M58.3189 37.4882C57.2747 37.4882 56.3349 37.0705 55.6039 36.3395C55.3951 36.1306 55.3951 35.7129 55.6039 35.5041C55.8128 35.2952 56.2305 35.2952 56.4393 35.5041C56.9614 36.0262 57.588 36.2351 58.3189 36.2351C59.0499 36.2351 59.9897 35.7129 60.4074 35.1908C60.6163 34.982 61.034 34.982 61.2428 35.1908C61.4517 35.3997 61.4517 35.8174 61.2428 36.0262C60.7207 36.7572 59.4676 37.4882 58.3189 37.4882Z"
                fill="#0DD1C5"
            />
            <path
                d="M63.647 37.4881C62.2895 37.4881 61.1408 36.966 60.5142 36.1306C60.3054 35.8173 60.4098 35.504 60.6187 35.2952C60.9319 35.0863 61.2452 35.1908 61.4541 35.3996C61.8718 35.9217 62.6027 36.235 63.5426 36.235C65.0045 36.235 66.2576 34.9819 66.2576 33.52C66.2576 32.058 65.0045 30.8049 63.5426 30.8049C62.8116 30.8049 62.2895 30.8049 61.9762 31.1182C61.6629 31.327 61.3496 31.2226 61.1408 31.0138C60.9319 30.7005 61.0364 30.3872 61.2452 30.1784C61.9762 29.6562 62.8116 29.6562 63.5426 29.6562C65.7355 29.6562 67.5107 31.4315 67.5107 33.6244C67.5107 35.8173 65.8399 37.4881 63.647 37.4881Z"
                fill="#0DD1C5"
            />
            <path
                d="M61.6633 31.3272C61.35 31.3272 61.0367 31.014 61.0367 30.7007C61.0367 29.2388 59.8881 28.1945 58.3217 28.1945C56.9642 28.1945 55.8155 29.2388 55.7111 30.5963C55.7111 30.9095 55.3978 31.2228 54.9801 31.1184C54.6668 31.1184 54.3536 30.8051 54.458 30.3874C54.6668 28.4034 56.3376 26.9414 58.3217 26.9414C60.5146 26.9414 62.2898 28.5078 62.2898 30.7007C62.2898 31.014 61.9766 31.3272 61.6633 31.3272Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
