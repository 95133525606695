import { ReactElement } from 'react';

export const SunsetCruisesAndTours = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M66.0867 49.2994H19.3367C19.1676 49.2994 19.0055 49.2322 18.8859 49.1127C18.7664 48.9931 18.6992 48.831 18.6992 48.6619C18.6992 48.4928 18.7664 48.3307 18.8859 48.2111C19.0055 48.0916 19.1676 48.0244 19.3367 48.0244H66.0867C66.2558 48.0244 66.4179 48.0916 66.5375 48.2111C66.6571 48.3307 66.7242 48.4928 66.7242 48.6619C66.7242 48.831 66.6571 48.9931 66.5375 49.1127C66.4179 49.2322 66.2558 49.2994 66.0867 49.2994Z"
                fill="#0DD1C5"
            />
            <path
                d="M58.5143 53.8473H26.8348C26.6657 53.8473 26.5035 53.7801 26.384 53.6605C26.2644 53.541 26.1973 53.3788 26.1973 53.2098C26.1973 53.0407 26.2644 52.8785 26.384 52.759C26.5035 52.6394 26.6657 52.5723 26.8348 52.5723H58.5143C58.6833 52.5723 58.8455 52.6394 58.965 52.759C59.0846 52.8785 59.1518 53.0407 59.1518 53.2098C59.1518 53.3788 59.0846 53.541 58.965 53.6605C58.8455 53.7801 58.6833 53.8473 58.5143 53.8473Z"
                fill="#0DD1C5"
            />
            <path
                d="M52.3759 58.3521H32.9109C32.7419 58.3521 32.5797 58.285 32.4602 58.1654C32.3406 58.0459 32.2734 57.8837 32.2734 57.7146C32.2734 57.5456 32.3406 57.3834 32.4602 57.2639C32.5797 57.1443 32.7419 57.0771 32.9109 57.0771H52.3759C52.545 57.0771 52.7072 57.1443 52.8267 57.2639C52.9463 57.3834 53.0134 57.5456 53.0134 57.7146C53.0134 57.8837 52.9463 58.0459 52.8267 58.1654C52.7072 58.285 52.545 58.3521 52.3759 58.3521Z"
                fill="#0DD1C5"
            />
            <path
                d="M27.0287 40.4678C26.9555 40.4795 26.8809 40.4795 26.8077 40.4678L21.0532 38.3428C20.9704 38.3146 20.8943 38.2697 20.8297 38.2109C20.765 38.1521 20.7131 38.0806 20.6772 38.0009C20.6414 37.9212 20.6223 37.8349 20.6211 37.7475C20.62 37.6601 20.6369 37.5734 20.6707 37.4928C20.6999 37.4108 20.7454 37.3355 20.8044 37.2715C20.8634 37.2075 20.9348 37.1561 21.0142 37.1204C21.0935 37.0846 21.1793 37.0653 21.2664 37.0636C21.3534 37.0619 21.4399 37.0778 21.5207 37.1103L27.2752 39.2353C27.3579 39.2635 27.434 39.3083 27.4987 39.3672C27.5634 39.426 27.6152 39.4975 27.6511 39.5772C27.687 39.6569 27.7061 39.7431 27.7072 39.8306C27.7083 39.918 27.6915 40.0047 27.6577 40.0853C27.6063 40.207 27.5179 40.3095 27.405 40.3782C27.2921 40.4468 27.1604 40.4782 27.0287 40.4678Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M33.1493 32.5291C33.0524 32.5322 32.9561 32.5131 32.8678 32.4733C32.7794 32.4335 32.7013 32.3741 32.6393 32.2996L29.1288 27.1996C29.0318 27.061 28.994 26.8895 29.0234 26.723C29.0529 26.5564 29.1474 26.4083 29.286 26.3114C29.4247 26.2145 29.5961 26.1766 29.7627 26.2061C29.9293 26.2355 30.0773 26.33 30.1743 26.4686L33.6848 31.5091C33.7726 31.6436 33.8057 31.8065 33.7772 31.9646C33.7488 32.1226 33.661 32.2638 33.5318 32.3591C33.4267 32.4567 33.2921 32.5165 33.1493 32.5291Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M43.3746 22.7371C43.3746 22.385 43.0892 22.0996 42.7371 22.0996C42.385 22.0996 42.0996 22.385 42.0996 22.7371V28.8741C42.0996 29.2262 42.385 29.5116 42.7371 29.5116C43.0892 29.5116 43.3746 29.2262 43.3746 28.8741V22.7371Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M52.3171 32.5208C52.186 32.5191 52.0585 32.4776 51.9516 32.4018C51.8227 32.3064 51.7345 32.1659 51.7046 32.0084C51.6746 31.8508 51.7052 31.6878 51.7901 31.5518L55.3006 26.5113C55.3972 26.384 55.5376 26.297 55.6946 26.2672C55.8515 26.2374 56.014 26.2668 56.1506 26.3498C56.2781 26.4464 56.3646 26.5875 56.393 26.7449C56.4213 26.9024 56.3894 27.0647 56.3036 27.1998L52.7931 32.2403C52.7413 32.3211 52.6712 32.3886 52.5885 32.4373C52.5059 32.486 52.4129 32.5146 52.3171 32.5208Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M58.4525 40.4601C58.3001 40.464 58.1513 40.4131 58.0332 40.3167C57.9151 40.2203 57.8354 40.0847 57.8087 39.9346C57.782 39.7845 57.8099 39.6298 57.8875 39.4985C57.9651 39.3672 58.0871 39.2681 58.2315 39.2191L63.9945 37.0941C64.1535 37.0355 64.3292 37.0425 64.483 37.1134C64.6368 37.1843 64.7562 37.3135 64.8148 37.4724C64.8734 37.6313 64.8665 37.807 64.7955 37.9609C64.7246 38.1147 64.5955 38.234 64.4365 38.2926L58.648 40.4176C58.5855 40.442 58.5195 40.4564 58.4525 40.4601Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.4992 32.9371C35.3402 32.9371 29.5367 38.7406 29.5367 45.8996C29.5367 46.2517 29.2513 46.5371 28.8992 46.5371C28.5471 46.5371 28.2617 46.2517 28.2617 45.8996C28.2617 38.0365 34.6361 31.6621 42.4992 31.6621C50.3624 31.6621 56.7367 38.0365 56.7367 45.8996C56.7367 46.2517 56.4513 46.5371 56.0992 46.5371C55.7471 46.5371 55.4617 46.2517 55.4617 45.8996C55.4617 38.7406 49.6582 32.9371 42.4992 32.9371Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
