import { ReactElement } from 'react';

export const BusAndCityTours = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M58.5225 63.4356H56.4663C56.1236 63.4356 55.7809 63.1772 55.7809 62.7466C55.7809 62.316 56.0379 62.0576 56.4663 62.0576H58.5225C60.0646 62.0576 61.264 60.8519 61.264 59.3016V44.4021C61.264 43.627 60.6643 43.0241 59.8933 43.0241H23.0534C22.2823 43.0241 21.5969 43.3686 21.1685 44.0576L17.5702 49.7418C16.7135 51.0337 16.2851 52.5839 16.2851 54.1341V59.3016C16.2851 60.8519 17.4846 62.0576 19.0267 62.0576H22.368C22.7107 62.0576 23.0534 62.316 23.0534 62.7466C23.0534 63.1772 22.7963 63.4356 22.368 63.4356H19.0267C16.7992 63.4356 15 61.627 15 59.3877V54.2203C15 52.4117 15.514 50.603 16.4565 49.1389L20.0548 43.4547C20.6545 42.4212 21.7683 41.8184 22.9677 41.8184H59.8933C61.3497 41.8184 62.5492 43.0241 62.5492 44.4882V59.4739C62.5492 61.627 60.75 63.4356 58.5225 63.4356Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M47.5555 63.4356H31.3631C31.0204 63.4356 30.6777 63.1772 30.6777 62.7466C30.6777 62.316 30.9348 62.0576 31.3631 62.0576H47.5555C47.8982 62.0576 48.2409 62.316 48.2409 62.7466C48.2409 63.1772 47.8982 63.4356 47.5555 63.4356Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M61.9489 46.0381H19.9686C19.6259 46.0381 19.2832 45.7798 19.2832 45.3492C19.2832 44.9185 19.5402 44.6602 19.9686 44.6602H61.9489C62.2916 44.6602 62.6343 44.9185 62.6343 45.3492C62.6343 45.7798 62.2916 46.0381 61.9489 46.0381Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M22.4537 56.3733H15.6854C15.3427 56.3733 15 56.1149 15 55.6843C15 55.2536 15.257 54.9953 15.6854 54.9953H22.4537C24.5955 54.9953 26.309 53.2728 26.309 51.1197V45.2632C26.309 44.9187 26.566 44.5742 26.9944 44.5742C27.4228 44.5742 27.6798 44.8326 27.6798 45.2632V51.1197C27.5941 54.0479 25.2809 56.3733 22.4537 56.3733Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M35.7323 52.4114C35.3896 52.4114 35.0469 52.153 35.0469 51.7224V45.3492C35.0469 45.0047 35.3039 44.6602 35.7323 44.6602C36.1606 44.6602 36.4177 45.0047 36.4177 45.3492V51.7224C36.4177 52.153 36.075 52.4114 35.7323 52.4114Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M44.3006 52.4114C43.9579 52.4114 43.6152 52.153 43.6152 51.7224V45.3492C43.6152 45.0047 43.8723 44.6602 44.3006 44.6602C44.729 44.6602 44.986 45.0047 44.986 45.3492V51.7224C44.986 52.153 44.6433 52.4114 44.3006 52.4114Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M52.867 52.4114C52.5243 52.4114 52.1816 52.153 52.1816 51.7224V45.3492C52.1816 45.0047 52.4387 44.6602 52.867 44.6602C53.2954 44.6602 53.5524 44.9185 53.5524 45.3492V51.7224C53.4668 52.153 53.2097 52.4114 52.867 52.4114Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M61.9488 52.4981H26.908C26.5654 52.4981 26.2227 52.2397 26.2227 51.8091C26.2227 51.3785 26.4797 51.1201 26.908 51.1201H61.8631C62.2058 51.1201 62.5485 51.3785 62.5485 51.8091C62.5485 52.2397 62.2915 52.4981 61.9488 52.4981Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.9086 68.0002C24.167 68.0002 21.9395 65.761 21.9395 63.005C21.9395 60.249 24.167 58.0098 26.9086 58.0098C29.6501 58.0098 31.8777 60.249 31.8777 63.005C31.8777 65.761 29.6501 68.0002 26.9086 68.0002ZM26.9086 59.2155C24.8524 59.2155 23.2246 60.8519 23.2246 62.9189C23.2246 64.9858 24.8524 66.6222 26.9086 66.6222C28.9647 66.6222 30.5925 64.9858 30.5925 62.9189C30.5925 60.8519 28.9647 59.2155 26.9086 59.2155Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.9092 65.2441C25.6241 65.2441 24.6816 64.2106 24.6816 63.0049C24.6816 61.7991 25.7097 60.7656 26.9092 60.7656C28.1086 60.7656 29.1367 61.7991 29.1367 63.0049C29.1367 64.2106 28.1086 65.2441 26.9092 65.2441ZM26.9092 61.9714C26.3951 61.9714 25.9668 62.402 25.9668 62.9187C25.9668 63.4355 26.3951 63.8661 26.9092 63.8661C27.4232 63.8661 27.8516 63.4355 27.8516 62.9187C27.8516 62.402 27.4232 61.9714 26.9092 61.9714Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M52.0121 68.0004C49.2705 68.0004 47.043 65.7611 47.043 63.0052C47.043 60.2492 49.2705 57.9238 52.0121 57.9238C54.7536 57.9238 56.9812 60.1631 56.9812 62.919C56.9812 65.675 54.7536 68.0004 52.0121 68.0004ZM52.0121 59.2157C49.9559 59.2157 48.3281 60.8521 48.3281 62.919C48.3281 64.986 49.9559 66.6224 52.0121 66.6224C54.0682 66.6224 55.6961 64.986 55.6961 62.919C55.6961 60.8521 54.0682 59.2157 52.0121 59.2157Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M52.0107 65.2441C50.7256 65.2441 49.7832 64.2106 49.7832 63.0049C49.7832 61.7991 50.8113 60.7656 52.0107 60.7656C53.2102 60.7656 54.2383 61.7991 54.2383 63.0049C54.2383 64.2106 53.2958 65.2441 52.0107 65.2441ZM52.0107 61.9714C51.4967 61.9714 51.0683 62.402 51.0683 62.9187C51.0683 63.4355 51.4967 63.8661 52.0107 63.8661C52.5248 63.8661 52.9531 63.4355 52.9531 62.9187C52.9531 62.402 52.5248 61.9714 52.0107 61.9714Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M21.1694 25.6601C19.2846 25.6601 17.8281 24.1098 17.8281 22.3012C17.8281 20.4926 19.2846 18.9424 21.1694 18.9424C21.6835 18.9424 22.1118 19.0285 22.5402 19.2008C22.8829 19.373 22.9686 19.7175 22.8829 20.062C22.7116 20.4065 22.3689 20.5787 22.0262 20.4065C21.7691 20.3204 21.5121 20.2342 21.1694 20.2342C20.0557 20.2342 19.1132 21.1816 19.1132 22.3012C19.1132 23.4209 20.0557 24.3682 21.1694 24.3682C21.6835 24.3682 22.1975 24.196 22.6259 23.7653C22.8829 23.507 23.3113 23.507 23.5683 23.7653C23.8253 24.0237 23.8253 24.4543 23.5683 24.7127C22.8829 25.3156 22.0262 25.6601 21.1694 25.6601Z"
                fill="#0DD1C5"
            />
            <path
                d="M24.9396 25.6602C24.0828 25.6602 23.2261 25.3157 22.6264 24.7128C22.3693 24.4544 22.3693 24.0238 22.6264 23.7654C22.8834 23.5071 23.3118 23.5071 23.5688 23.7654C23.9971 24.1099 24.5112 24.3683 25.0252 24.3683C25.625 24.3683 26.3104 23.9377 26.653 23.5932C26.9101 23.3348 27.3384 23.3348 27.5955 23.5932C27.8525 23.8516 27.8525 24.2822 27.5955 24.5406C26.9957 25.0573 25.9677 25.6602 24.9396 25.6602Z"
                fill="#0DD1C5"
            />
            <path
                d="M29.3091 25.6601C28.1097 25.6601 27.253 25.2295 26.6532 24.4543C26.3962 24.196 26.4819 23.7653 26.7389 23.507C26.9959 23.2486 27.4243 23.3347 27.6813 23.5931C28.024 24.0237 28.5381 24.2821 29.3091 24.2821C30.4229 24.2821 31.3653 23.3347 31.3653 22.2151C31.3653 21.0955 30.4229 20.2342 29.3091 20.2342C28.7094 20.2342 28.3667 20.2342 28.1097 20.4926C27.8527 20.6649 27.4243 20.6649 27.253 20.3204C27.0816 20.062 27.0816 19.6314 27.4243 19.4591C28.024 19.0285 28.7094 18.9424 29.3948 18.9424C31.2796 18.9424 32.7361 20.4926 32.7361 22.3012C32.7361 24.1098 31.194 25.6601 29.3091 25.6601Z"
                fill="#0DD1C5"
            />
            <path
                d="M27.6805 20.5787C27.3378 20.5787 26.9951 20.3203 26.9951 19.8897C26.9951 18.7701 26.1384 17.995 24.9389 17.995C23.9109 17.995 22.9684 18.7701 22.8828 19.8036C22.8828 20.1481 22.5401 20.4065 22.1974 20.4065C21.8547 20.4065 21.5977 20.1481 21.5977 19.7175C21.769 17.995 23.2255 16.7031 24.9389 16.7031C26.8238 16.7031 28.2802 18.0811 28.2802 19.8897C28.2802 20.3203 28.0232 20.5787 27.6805 20.5787Z"
                fill="#0DD1C5"
            />
            <path
                d="M70.1737 58.0435C69.831 58.0435 69.4883 57.7851 69.4883 57.3545V47.7085C69.4883 47.364 69.7453 47.0195 70.1737 47.0195C70.602 47.0195 70.8591 47.2779 70.8591 47.7085V57.4406C70.7734 57.7851 70.5164 58.0435 70.1737 58.0435Z"
                fill="#0DD1C5"
            />
            <path
                d="M70.2592 53.3063C70.1736 53.3063 70.0022 53.3063 69.9166 53.2202H69.8309C67.0036 51.8422 65.2045 50.1197 64.6048 48.2249C62.7199 42.1101 69.4882 38.2345 69.5739 38.2345C69.7452 38.1484 70.0022 38.1484 70.1736 38.2345C70.2593 38.2345 77.5416 42.1101 75.6567 48.2249C75.057 50.0336 73.2578 51.756 70.6019 53.134H70.5163C70.4306 53.2202 70.3449 53.3063 70.2592 53.3063ZM69.8309 39.4403C68.6314 40.2154 64.3477 43.3159 65.8042 47.7943C66.2326 49.2584 67.8604 50.7226 70.1736 51.9283C72.4011 50.7226 73.9432 49.2584 74.3716 47.7943C75.8281 43.3159 71.116 40.2154 69.8309 39.4403Z"
                fill="#0DD1C5"
            />
            <path
                d="M67.7755 38.4073C67.4328 38.4073 67.0901 38.1489 67.0901 37.7183V30.3116H55.7811C55.4384 30.3116 55.0957 30.0532 55.0957 29.6226C55.0957 29.192 55.3527 28.9336 55.7811 28.9336H67.7755C68.1182 28.9336 68.4609 29.192 68.4609 29.6226V37.7183C68.3752 38.0628 68.1182 38.4073 67.7755 38.4073Z"
                fill="#0DD1C5"
            />
            <path
                d="M55.6953 40.1293C55.3526 40.1293 55.0099 39.871 55.0099 39.4403V20.4068L45.0717 14.7226L44.9003 31.4308C44.9003 31.7753 44.6433 32.0336 44.215 32.0336C43.8723 32.0336 43.6152 31.7753 43.6152 31.3446L43.7866 13.5169C43.7866 13.2585 43.8723 13.0863 44.1293 13.0001C44.3006 12.914 44.5576 12.914 44.729 13.0001L55.9523 19.3733C56.1237 19.4595 56.295 19.7178 56.295 19.9762V39.3542C56.295 39.7848 56.038 40.1293 55.6953 40.1293Z"
                fill="#0DD1C5"
            />
            <path
                d="M63.0635 34.5313H58.3514C58.0087 34.5313 57.666 34.2729 57.666 33.8423C57.666 33.4117 57.923 33.1533 58.3514 33.1533H63.0635C63.4062 33.1533 63.7489 33.4117 63.7489 33.8423C63.7489 34.2729 63.4062 34.5313 63.0635 34.5313Z"
                fill="#0DD1C5"
            />
            <path
                d="M50.9834 22.0428H46.2713C45.9286 22.0428 45.5859 21.7845 45.5859 21.3538C45.5859 20.9232 45.9286 20.751 46.2713 20.751H50.9834C51.3261 20.751 51.6688 21.0093 51.6688 21.44C51.6688 21.8706 51.3261 22.0428 50.9834 22.0428Z"
                fill="#0DD1C5"
            />
            <path
                d="M50.8975 26.1768H46.1854C45.8427 26.1768 45.5 25.9184 45.5 25.4878C45.5 25.0572 45.757 24.7988 46.1854 24.7988H50.8975C51.2402 24.7988 51.5829 25.0572 51.5829 25.4878C51.5829 25.9184 51.2402 26.1768 50.8975 26.1768Z"
                fill="#0DD1C5"
            />
            <path
                d="M50.9834 30.3116H46.2713C45.9286 30.3116 45.5859 30.0532 45.5859 29.6226C45.5859 29.192 45.843 28.9336 46.2713 28.9336H50.9834C51.3261 28.9336 51.6688 29.192 51.6688 29.6226C51.6688 30.0532 51.3261 30.3116 50.9834 30.3116Z"
                fill="#0DD1C5"
            />
            <path
                d="M39.246 36.3399H34.1913C33.8486 36.3399 33.5059 36.0815 33.5059 35.6509C33.5059 35.2203 33.7629 34.9619 34.1913 34.9619H39.246C39.5887 34.9619 39.9314 35.2203 39.9314 35.6509C39.9314 36.0815 39.5887 36.3399 39.246 36.3399Z"
                fill="#0DD1C5"
            />
            <path
                d="M43.7866 36.3399H41.1307C40.788 36.3399 40.4453 36.0815 40.4453 35.6509C40.4453 35.2203 40.7023 34.9619 41.1307 34.9619H43.7866C44.1293 34.9619 44.472 35.2203 44.472 35.6509C44.472 36.0815 44.1293 36.3399 43.7866 36.3399Z"
                fill="#0DD1C5"
            />
            <path
                d="M46.1846 40.3872C45.842 40.3872 45.4993 40.1289 45.4993 39.6982V32.8944H32.6481V39.6982C32.6481 40.0427 32.3911 40.3872 31.9627 40.3872C31.5344 40.3872 31.2773 40.1289 31.2773 39.6982V32.2915C31.2773 31.947 31.5344 31.6025 31.9627 31.6025H46.1846C46.5273 31.6025 46.87 31.8609 46.87 32.2915V39.6982C46.7844 40.0427 46.5273 40.3872 46.1846 40.3872Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
