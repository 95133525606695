import { ReactElement } from 'react';

export const VoodooGhostAndCemeteryTours = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C50.9057 85 59.1226 82.5074 66.1117 77.8375C73.1008 73.1675 78.5481 66.5299 81.7648 58.764C84.9816 50.9982 85.8232 42.4529 84.1834 34.2087C82.5435 25.9645 78.4958 18.3917 72.552 12.448C66.6083 6.50425 59.0355 2.45652 50.7913 0.816645C42.5471 -0.823227 34.0018 0.0184145 26.2359 3.23514C18.4701 6.45186 11.8325 11.8992 7.16253 18.8883C2.49258 25.8774 0 34.0943 0 42.5C0 53.7717 4.47766 64.5817 12.448 72.552C20.4182 80.5223 31.2283 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.5012 29.5377C35.3422 29.5377 29.5387 35.3412 29.5387 42.5002V59.5002H28.2637V42.5002C28.2637 34.637 34.638 28.2627 42.5012 28.2627C50.3643 28.2627 56.7387 34.637 56.7387 42.5002V59.5002H55.4637V42.5002C55.4637 35.3412 49.6602 29.5377 42.5012 29.5377Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.4629 60.0606C21.4629 59.3988 21.9994 58.8623 22.6612 58.8623H62.3396C63.0014 58.8623 63.5379 59.3988 63.5379 60.0606V65.739C63.5379 66.4008 63.0014 66.9373 62.3396 66.9373H22.6612C21.9994 66.9373 21.4629 66.4008 21.4629 65.739V60.0606ZM22.7379 60.1373V65.6623H62.2629V60.1373H22.7379Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.2413 12.461C40.2413 11.7992 40.7778 11.2627 41.4397 11.2627H43.683C44.3448 11.2627 44.8813 11.7992 44.8813 12.461V14.9081H47.8891C48.551 14.9081 49.0875 15.4446 49.0875 16.1064V18.3497C49.0875 19.0115 48.551 19.548 47.8891 19.548H44.8813V25.36C44.8813 26.0218 44.3448 26.5583 43.683 26.5583H41.4397C40.7778 26.5583 40.2413 26.0218 40.2413 25.36V19.548H37.2335C36.5717 19.548 36.0352 19.0115 36.0352 18.3497V16.1064C36.0352 15.4446 36.5717 14.9081 37.2335 14.9081H40.2413V12.461ZM41.5163 12.5377V15.5456C41.5163 15.8976 41.2309 16.1831 40.8788 16.1831H37.3102V18.273H40.8788C41.2309 18.273 41.5163 18.5584 41.5163 18.9105V25.2833H43.6063V18.9105C43.6063 18.5584 43.8917 18.273 44.2438 18.273H47.8125V16.1831H44.2438C43.8917 16.1831 43.6063 15.8976 43.6063 15.5456V12.5377H41.5163Z"
                fill="#0DD1C5"
            />
            <path
                d="M68.7391 44.727H67.6171V43.1375C67.6171 42.9684 67.5499 42.8063 67.4304 42.6867C67.3108 42.5672 67.1487 42.5 66.9796 42.5C66.8105 42.5 66.6484 42.5672 66.5288 42.6867C66.4093 42.8063 66.3421 42.9684 66.3421 43.1375V44.727H65.2371C65.068 44.727 64.9059 44.7942 64.7863 44.9137C64.6668 45.0333 64.5996 45.1954 64.5996 45.3645C64.5996 45.5336 64.6668 45.6957 64.7863 45.8153C64.9059 45.9348 65.068 46.002 65.2371 46.002H66.3421V47.6C66.3421 47.7691 66.4093 47.9312 66.5288 48.0508C66.6484 48.1703 66.8105 48.2375 66.9796 48.2375C67.1487 48.2375 67.3108 48.1703 67.4304 48.0508C67.5499 47.9312 67.6171 47.7691 67.6171 47.6V46.002H68.7391C68.9082 46.002 69.0703 45.9348 69.1899 45.8153C69.3094 45.6957 69.3766 45.5336 69.3766 45.3645C69.3766 45.1954 69.3094 45.0333 69.1899 44.9137C69.0703 44.7942 68.9082 44.727 68.7391 44.727V44.727Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M23.6903 27.727H22.5683V26.1375C22.5683 25.9684 22.5011 25.8063 22.3816 25.6867C22.262 25.5672 22.0999 25.5 21.9308 25.5C21.7617 25.5 21.5996 25.5672 21.48 25.6867C21.3605 25.8063 21.2933 25.9684 21.2933 26.1375V27.727H20.1883C20.0192 27.727 19.8571 27.7942 19.7375 27.9137C19.6179 28.0333 19.5508 28.1954 19.5508 28.3645C19.5508 28.5336 19.6179 28.6957 19.7375 28.8153C19.8571 28.9348 20.0192 29.002 20.1883 29.002H21.2933V30.6C21.2933 30.7691 21.3605 30.9312 21.48 31.0508C21.5996 31.1703 21.7617 31.2375 21.9308 31.2375C22.0999 31.2375 22.262 31.1703 22.3816 31.0508C22.5011 30.9312 22.5683 30.7691 22.5683 30.6V29.002H23.6903C23.8594 29.002 24.0215 28.9348 24.1411 28.8153C24.2606 28.6957 24.3278 28.5336 24.3278 28.3645C24.3278 28.1954 24.2606 28.0333 24.1411 27.9137C24.0215 27.7942 23.8594 27.727 23.6903 27.727V27.727Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.2703 36.5691C38.3594 36.5691 35.157 39.7715 35.157 43.6824V52.8612C35.157 53.5711 35.7369 54.1509 36.4468 54.1509C36.8964 54.1509 37.2558 53.9466 37.4961 53.5995C37.6847 53.327 38.0585 53.2591 38.331 53.4477C38.6034 53.6363 38.6714 54.0101 38.4827 54.2825C38.0289 54.9381 37.3084 55.3509 36.4468 55.3509C35.0741 55.3509 33.957 54.2338 33.957 52.8612V43.6824C33.957 39.1087 37.6966 35.3691 42.2703 35.3691C46.8439 35.3691 50.5835 39.1087 50.5835 43.6824V52.3598C50.5835 53.6168 49.5821 54.6181 48.3252 54.6181C46.9525 54.6181 45.8354 53.5011 45.8354 52.1284V51.8584C45.8354 51.3543 45.7246 51.1181 45.6404 51.0163C45.5718 50.9333 45.4701 50.8772 45.2784 50.8772C44.9797 50.8772 44.7215 51.1229 44.7215 51.4342V54.8666C44.7215 56.1621 43.6429 57.2406 42.3474 57.2406C41.0519 57.2406 39.9734 56.1621 39.9734 54.8666V52.6298C39.9734 52.331 39.7277 52.0728 39.4164 52.0728C39.201 52.0728 39.0123 52.1956 38.9117 52.3967C38.7636 52.6931 38.4031 52.8132 38.1068 52.6651C37.8104 52.5169 37.6902 52.1565 37.8384 51.8601C38.1235 51.2898 38.7061 50.8728 39.4164 50.8728C40.4163 50.8728 41.1734 51.6944 41.1734 52.6298V54.8666C41.1734 55.4994 41.7146 56.0406 42.3474 56.0406C42.9802 56.0406 43.5215 55.4994 43.5215 54.8666V51.4342C43.5215 50.4343 44.3431 49.6772 45.2784 49.6772C45.7424 49.6772 46.2192 49.8332 46.5652 50.2516C46.8956 50.6512 47.0354 51.2055 47.0354 51.8584V52.1284C47.0354 52.8383 47.6153 53.4181 48.3252 53.4181C48.9194 53.4181 49.3835 52.954 49.3835 52.3598V43.6824C49.3835 39.7715 46.1812 36.5691 42.2703 36.5691Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.7777 40.498C45.1091 40.498 45.3777 40.7667 45.3777 41.098V43.1806C45.3777 43.512 45.1091 43.7806 44.7777 43.7806C44.4464 43.7806 44.1777 43.512 44.1777 43.1806V41.098C44.1777 40.7667 44.4464 40.498 44.7777 40.498Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.8051 40.498C40.1364 40.498 40.4051 40.7667 40.4051 41.098V43.1806C40.4051 43.512 40.1364 43.7806 39.8051 43.7806C39.4737 43.7806 39.2051 43.512 39.2051 43.1806V41.098C39.2051 40.7667 39.4737 40.498 39.8051 40.498Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.4707 42.1391C38.4707 41.8077 38.7393 41.5391 39.0707 41.5391H40.4977C40.829 41.5391 41.0977 41.8077 41.0977 42.1391C41.0977 42.4704 40.829 42.7391 40.4977 42.7391H39.0707C38.7393 42.7391 38.4707 42.4704 38.4707 42.1391Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.4844 42.1391C43.4844 41.8077 43.753 41.5391 44.0844 41.5391H45.5113C45.8427 41.5391 46.1113 41.8077 46.1113 42.1391C46.1113 42.4704 45.8427 42.7391 45.5113 42.7391H44.0844C43.753 42.7391 43.4844 42.4704 43.4844 42.1391Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
