import { ReactElement } from 'react';

export const BikeAndSegwayTours = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M26.4839 62.9376C22.5452 62.9376 19.375 59.7674 19.375 55.8287C19.375 51.8899 22.5452 48.7197 26.4839 48.7197C30.4227 48.7197 33.5929 51.8899 33.5929 55.8287C33.5929 59.7674 30.4227 62.9376 26.4839 62.9376ZM26.4839 49.9686C23.3137 49.9686 20.7199 52.5624 20.7199 55.7326C20.7199 58.9028 23.3137 61.4966 26.4839 61.4966C29.6542 61.4966 32.248 58.9028 32.248 55.7326C32.248 52.5624 29.6542 49.9686 26.4839 49.9686Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M17.1666 56.501C16.7823 56.501 16.4941 56.2128 16.4941 55.8285C16.4941 50.6409 20.5289 46.3179 25.7166 45.9336L23.2188 30.3708C23.1228 29.6983 22.5464 29.218 21.8739 29.218H19.3761C18.9919 29.218 18.7037 28.9298 18.7037 28.5455C18.7037 28.1612 18.9919 27.873 19.3761 27.873H21.8739C23.2188 27.873 24.2756 28.8337 24.5638 30.1787L27.1576 46.51C27.1576 46.7022 27.1576 46.8943 26.9654 47.0864C26.8694 47.2786 26.6772 47.2786 26.4851 47.2786C21.7778 47.2786 17.8391 51.1212 17.8391 55.9246C17.8391 56.1167 17.5509 56.501 17.1666 56.501Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.4843 58.8065C24.8512 58.8065 23.4102 57.4615 23.4102 55.7323C23.4102 54.0031 24.7551 52.6582 26.4843 52.6582C28.1174 52.6582 29.5584 54.0031 29.5584 55.7323C29.5584 57.4615 28.1174 58.8065 26.4843 58.8065ZM26.4843 54.0992C25.5236 54.0992 24.7551 54.8677 24.7551 55.8284C24.7551 56.7891 25.5236 57.5576 26.4843 57.5576C27.445 57.5576 28.2135 56.7891 28.2135 55.8284C28.2135 54.8677 27.445 54.0992 26.4843 54.0992Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M30.7098 66.299H14.6666C14.2823 66.299 13.9941 66.0108 13.9941 65.6266C13.9941 65.2423 14.2823 64.9541 14.6666 64.9541H30.7098C31.094 64.9541 31.3822 65.2423 31.3822 65.6266C31.3822 66.0108 31.094 66.299 30.7098 66.299Z"
                fill="#0DD1C5"
            />
            <path
                d="M19.1831 63.4172H9.67247C9.2882 63.4172 9 63.129 9 62.7447C9 62.3605 9.2882 62.0723 9.67247 62.0723H19.1831C19.5674 62.0723 19.8556 62.3605 19.8556 62.7447C19.8556 63.129 19.5674 63.4172 19.1831 63.4172Z"
                fill="#0DD1C5"
            />
            <path
                d="M45.8899 42.3785C41.4709 42.3785 37.8203 38.824 37.8203 34.3089C37.8203 29.7937 41.3748 26.2393 45.8899 26.2393C50.4051 26.2393 53.9595 29.7937 53.9595 34.3089C53.9595 38.824 50.309 42.3785 45.8899 42.3785ZM45.8899 27.6803C42.2394 27.6803 39.1653 30.6583 39.1653 34.4049C39.1653 38.1516 42.1433 41.1296 45.8899 41.1296C49.6365 41.1296 52.6146 38.1516 52.6146 34.4049C52.6146 30.6583 49.5405 27.6803 45.8899 27.6803Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M67.3138 42.3785C62.8947 42.3785 59.2441 38.824 59.2441 34.3089C59.2441 29.7937 62.7986 26.2393 67.3138 26.2393C71.8289 26.2393 75.2873 29.8898 75.2873 34.3089C75.2873 38.728 71.7328 42.3785 67.3138 42.3785ZM67.3138 27.6803C63.6632 27.6803 60.5891 30.6583 60.5891 34.4049C60.5891 38.1516 63.5672 41.1296 67.3138 41.1296C71.0604 41.1296 74.0384 38.1516 74.0384 34.4049C74.0384 30.6583 70.9643 27.6803 67.3138 27.6803Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M45.8893 34.9817C45.505 34.9817 45.2168 34.6935 45.2168 34.3092C45.2168 33.925 45.505 33.6368 45.8893 33.6368C45.8893 33.6368 49.3477 33.3486 52.4218 31.2351C53.3825 30.5626 54.2471 29.698 55.2078 28.8334C57.5134 26.72 60.0111 24.4144 63.6616 23.838C64.0459 23.7419 64.3341 24.0301 64.4302 24.4144C64.5262 24.7986 64.238 25.0868 63.8538 25.1829C60.5875 25.6632 58.2819 27.7767 56.0724 29.7941C55.1117 30.6587 54.151 31.5233 53.1903 32.2918C49.828 34.6935 46.0814 34.9817 45.8893 34.9817Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M67.3131 34.9818C67.0249 34.9818 66.7367 34.7896 66.6406 34.5014L62.4137 21.5324C62.3176 21.2442 62.5097 20.8599 62.7979 20.7639L67.0249 19.0346C67.4091 18.9386 67.7934 19.0346 67.8895 19.4189C67.9855 19.8032 67.8895 20.1875 67.5052 20.2835L63.8547 21.8206L67.8895 34.2132C67.9855 34.5975 67.7934 34.9818 67.4091 35.0778C67.4091 34.9818 67.3131 34.9818 67.3131 34.9818Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M53.4798 28.6414C53.1915 28.6414 52.9033 28.4493 52.8073 28.1611L51.3663 23.5499C51.2702 23.2617 51.4623 22.8774 51.8466 22.7813C52.2309 22.6853 52.6151 22.8774 52.7112 23.2617L54.1522 27.7768C54.2483 28.1611 54.0562 28.5453 53.6719 28.6414C53.6719 28.6414 53.5758 28.6414 53.4798 28.6414Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M55.3038 23.7424H50.0201C49.6359 23.7424 49.3477 23.4542 49.3477 23.0699C49.3477 22.6857 49.6359 22.3975 50.0201 22.3975H55.3038C55.6881 22.3975 55.9763 22.6857 55.9763 23.0699C55.9763 23.4542 55.6881 23.7424 55.3038 23.7424Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M62.8948 47.4709H32.5377C32.1534 47.4709 31.8652 47.1827 31.8652 46.7984C31.8652 46.4142 32.1534 46.126 32.5377 46.126H62.7988C63.183 46.126 63.4712 46.4142 63.4712 46.7984C63.4712 47.1827 63.183 47.4709 62.8948 47.4709Z"
                fill="#0DD1C5"
            />
            <path
                d="M34.6516 42.1867H28.3111C27.9269 42.1867 27.6387 41.8985 27.6387 41.5143C27.6387 41.13 27.9269 40.8418 28.3111 40.8418H34.6516C35.0358 40.8418 35.324 41.13 35.324 41.5143C35.324 41.8985 35.0358 42.1867 34.6516 42.1867Z"
                fill="#0DD1C5"
            />
            <path
                d="M53.9613 52.8498H44.7389C44.3546 52.8498 44.0664 52.5616 44.0664 52.1773C44.0664 51.7931 44.3546 51.5049 44.7389 51.5049H53.9613C54.3456 51.5049 54.6338 51.7931 54.6338 52.1773C54.6338 52.5616 54.3456 52.8498 53.9613 52.8498Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
