import { FC } from 'react';
import { AirboatTours } from './AirboatTours';
import { AirplaneTours } from './AirplaneTours';
import { Aquariums } from './Aquariums';
import { BayouAndSwampTours } from './BayouAndSwampTours';
import { BeachBonfire } from './BeachBonfire';
import { BikeRentals } from './BikeRentals';
import { BikeTours } from './BikeTours';
import { BoatTours } from './BoatTours';
import { BusTours } from './BusTours';
import { CanoeRentals } from './CanoeRentals';
import { CarriageRidesAndTours } from './CarriageRidesAndTours';
import { CityTours } from './CityTours';
import { ComedyandEntertainment } from './ComedyandEntertainment';
import { DeepSeaFishingCharters } from './DeepSeaFishingCharters';
import { DinnerCruises } from './DinnerCruises';
import { DolphinCruises } from './DolphinCruises';
import { FishingCharters } from './FishingCharters';
import { FlyingJetSki } from './FlyingJetSki';
import { FoodTours } from './FoodTours';
import { GhostTours } from './GhostTours';
import { HelicopterTours } from './HelicopterTours';
import { InshoreBayFishing } from './InshoreBayFishing';
import { KayakRentals } from './KayakRentals';
import { Museums } from './Museums';
import { Parasailing } from './Parasailing';
import { PlantationTours } from './PlantationTours';
import { PontoonBoatRentals } from './PontoonBoatRentals';
import { PubCrawls } from './PubCrawls';
import { SailboatRentals } from './SailboatRentals';
import { SailingCharters } from './SailingCharters';
import { SnorkelingTours } from './SnorkelingTours';
import { SunsetCruises } from './SunsetCruises';
import { TouristAttractions } from './TouristAttractions';
import { AnimalEncounters } from './AnimalEncounters';
import { SegwayTours } from './SegwayTours';
import { DayTripsAndExcursions } from './DayTripsAndExcursions';
import { BananaBoatRides } from './BananaBoatRides';
import { MagicShows } from './MagicShows';
import { CanoeKayakAndPaddleboard } from './CanoeKayakAndPaddleboard';
import { Waterparks } from './Waterparks';
import { SwimmingWithDolphins } from './SwimmingWithDolphins';
import { PirateCruise } from './PirateCruise';
import { CemeteryTours } from './CemeteryTours';
import { ManateeTours } from './ManateeTours';
import { Sailing } from './Sailing';
import { ZoosAndAquariums } from './ZoosAndAquariums';
import { WakeboardAndWaterSki } from './WakeboardAndWaterSki';
import { VoodooGhostAndCemeteryTours } from './VoodooGhostAndCemeteryTours';
import { SurfboardRentalsAndLessons } from './SurfboardRentalsAndLessons';
import { SunsetCruisesAndTours } from './SunsetCruisesAndTours';
import { ScubaDivingTours } from './ScubaDivingTours';
import { SailingChartersAndTours } from './SailingChartersAndTours';
import { LunchAndDinnerCruises } from './LunchAndDinnerCruises';
import { JetSkiRentalsAndTours } from './JetSkiRentalsAndTours';
import { HelicopterAndAirplaneTours } from './HelicopterAndAirplaneTours';
import { CarRentalsAndTransportation } from './CarRentalsAndTransportation';
import { BusAndCityTours } from './BusAndCityTours';
import { BikeAndSegwayTours } from './BikeAndSegwayTours';
import { BeachPhotography } from './BeachPhotography';
import { BabyAndMobilityRentals } from './BabyAndMobilityRentals';

export const CategoryIcon: { [key: string]: FC } = {
    'Dolphin-Cruises-and-Tours': DolphinCruises,
    'Sunset-Cruises': SunsetCruises,
    'Boat-Tours': BoatTours,
    'Sailing-Charters': SailingCharters,
    'Snorkeling-Tours': SnorkelingTours,
    'Food-Tours': FoodTours,
    'Bike-Tours': BikeTours,
    'City-Tours': CityTours,
    'Bayou-and-Swamp-Tours': BayouAndSwampTours,
    'Plantation-Tours': PlantationTours,
    'Bike-Rentals': BikeRentals,
    'Parasailing': Parasailing,
    'Pontoon-Boat-Rentals': PontoonBoatRentals,
    'boat-rentals': PontoonBoatRentals,
    'Flying-Jet-Ski': FlyingJetSki,
    'Jet-Ski-Waverunner-Rentals': FlyingJetSki,
    'Helicopter-Tours': HelicopterTours,
    'Deep-Sea-Fishing-Charters': DeepSeaFishingCharters,
    'Dinner-Cruises': DinnerCruises,
    'Airboat-Tours': AirboatTours,
    'Canoe-Rentals': CanoeRentals,
    'Carriage-Rides-and-Tours': CarriageRidesAndTours,
    'Pub-Crawls': PubCrawls,
    'Beach-Bonfire': BeachBonfire,
    'Sailboat-Rentals': SailboatRentals,
    'Speed-Boat-Rentals': SailboatRentals,
    'Fishing-Boat-Rentals': SailboatRentals,
    'Comedy-and-Entertainment': ComedyandEntertainment,
    'Airplane-Tours': AirplaneTours,
    'Fishing-Charters': FishingCharters,
    'Kayak-Rentals': KayakRentals,
    'Aquariums': Aquariums,
    'Inshore-Bay-Fishing': InshoreBayFishing,
    'Ghost-Tours': GhostTours,
    'Museums': Museums,
    'Bus-Tours': BusTours,
    'Tourist-Attractions': TouristAttractions,
    'Animal-Encounters': AnimalEncounters,
    'Segway-Tours': SegwayTours,
    'Day-Trips-and-Excursions': DayTripsAndExcursions,
    'Banana-Boat-Rides': BananaBoatRides,
    'Magic-Shows': MagicShows,
    'Waterparks': Waterparks,
    'Swimming-with-Dolphins': SwimmingWithDolphins,
    'Pirate-Cruises': PirateCruise,
    'Cemetery-Tours': CemeteryTours,
    'Manatee-Tours': ManateeTours,
    'Canoe-Kayak-and-Paddleboard': CanoeKayakAndPaddleboard,
    'Sailing': Sailing,
    'zoos-aquariums': ZoosAndAquariums,
    'wakeboard-water-ski': WakeboardAndWaterSki,
    'voodoo-ghost-cemetary-tours': VoodooGhostAndCemeteryTours,
    'surfboard-rentals-lessons': SurfboardRentalsAndLessons,
    'sunset-cruises-tours': SunsetCruisesAndTours,
    'scuba-diving-tours': ScubaDivingTours,
    'sailing-charters-tours': SailingChartersAndTours,
    'lunch-dinner-cruises': LunchAndDinnerCruises,
    'jet-ski-rentals-tours': JetSkiRentalsAndTours,
    'helicopter-airplane-tours': HelicopterAndAirplaneTours,
    'car-rentals-transportation': CarRentalsAndTransportation,
    'canoe-kayak-paddleboard-rentals': CanoeKayakAndPaddleboard,
    'bus-city-tours': BusAndCityTours,
    'bike-segway-tours': BikeAndSegwayTours,
    'beach-photographers': BeachPhotography,
    'baby-mobility-rentals': BabyAndMobilityRentals,
};
