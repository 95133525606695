import { ReactElement } from 'react';

export const BeachPhotography = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M64.4894 62.0497H20.5104C19.5433 62.0497 18.6158 61.6655 17.9319 60.9817C17.2481 60.2978 16.8639 59.3703 16.8639 58.4032V31.2032C16.8639 30.2361 17.2481 29.3086 17.9319 28.6248C18.6158 27.9409 19.5433 27.5567 20.5104 27.5567H30.3534C30.6037 27.5545 30.8483 27.4816 31.0589 27.3462C31.2695 27.2108 31.4375 27.0186 31.5434 26.7917L34.0934 21.2667C34.3349 20.7551 34.7177 20.3232 35.1966 20.022C35.6756 19.7208 36.2306 19.5628 36.7964 19.5667H48.5859C49.1516 19.5628 49.7067 19.7208 50.1856 20.022C50.6646 20.3232 51.0474 20.7551 51.2889 21.2667L53.8389 26.7832C53.941 27.0136 54.1078 27.2093 54.3191 27.3466C54.5303 27.4839 54.7769 27.5569 55.0289 27.5567H64.4894C65.4565 27.5567 66.384 27.9409 67.0679 28.6248C67.7517 29.3086 68.1359 30.2361 68.1359 31.2032V58.4032C68.1359 59.3703 67.7517 60.2978 67.0679 60.9817C66.384 61.6655 65.4565 62.0497 64.4894 62.0497ZM20.5104 28.8997C19.8814 28.8997 19.2782 29.1496 18.8335 29.5943C18.3887 30.0391 18.1389 30.6423 18.1389 31.2712V58.4712C18.1389 59.1002 18.3887 59.7034 18.8335 60.1481C19.2782 60.5929 19.8814 60.8427 20.5104 60.8427H64.4894C65.1184 60.8427 65.7216 60.5929 66.1663 60.1481C66.611 59.7034 66.8609 59.1002 66.8609 58.4712V31.2712C66.8609 30.6423 66.611 30.0391 66.1663 29.5943C65.7216 29.1496 65.1184 28.8997 64.4894 28.8997H54.9949C54.4977 28.9046 54.0099 28.7639 53.5915 28.4951C53.1732 28.2263 52.8426 27.841 52.6404 27.3867L50.0904 21.8617C49.9538 21.5666 49.7355 21.3169 49.4613 21.1421C49.1871 20.9674 48.8685 20.8749 48.5434 20.8757H36.7539C36.4287 20.8749 36.1102 20.9674 35.836 21.1421C35.5618 21.3169 35.3435 21.5666 35.2069 21.8617L32.6569 27.3867C32.4582 27.8331 32.1355 28.213 31.7271 28.4812C31.3188 28.7494 30.8419 28.8947 30.3534 28.8997H20.5104Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M42.5 57.7999C39.6841 57.7999 36.9314 56.9649 34.5901 55.4005C32.2487 53.8361 30.4239 51.6125 29.3463 49.0109C28.2687 46.4093 27.9867 43.5467 28.5361 40.7849C29.0854 38.0231 30.4414 35.4862 32.4326 33.495C34.4237 31.5039 36.9606 30.1479 39.7224 29.5985C42.4842 29.0492 45.3469 29.3311 47.9485 30.4087C50.55 31.4863 52.7736 33.3112 54.3381 35.6525C55.9025 37.9939 56.7375 40.7465 56.7375 43.5625C56.7353 47.3378 55.2345 50.9578 52.565 53.6274C49.8954 56.297 46.2753 57.7977 42.5 57.7999ZM42.5 30.6C39.9363 30.6 37.4301 31.3602 35.2984 32.7845C33.1668 34.2089 31.5053 36.2333 30.5242 38.6019C29.5431 40.9705 29.2864 43.5768 29.7866 46.0913C30.2868 48.6058 31.5213 50.9155 33.3341 52.7283C35.147 54.5412 37.4567 55.7757 39.9712 56.2759C42.4856 56.776 45.092 56.5193 47.4606 55.5382C49.8291 54.5571 51.8536 52.8957 53.2779 50.764C54.7023 48.6324 55.4625 46.1262 55.4625 43.5625C55.458 40.126 54.0909 36.8315 51.6609 34.4016C49.231 31.9716 45.9365 30.6044 42.5 30.6Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M42.5007 52.4702C40.7452 52.4718 39.0287 51.9528 37.5682 50.9788C36.1078 50.0047 34.9691 48.6194 34.2961 46.998C33.6231 45.3766 33.4462 43.5921 33.7876 41.8702C34.1291 40.1482 34.9736 38.5662 36.2143 37.3243C37.455 36.0824 39.0362 35.2364 40.7578 34.8933C42.4794 34.5502 44.2641 34.7254 45.8861 35.3968C47.5081 36.0682 48.8946 37.2057 49.87 38.6652C50.8455 40.1247 51.3662 41.8407 51.3662 43.5962C51.3639 45.9475 50.4294 48.2021 48.7676 49.8655C47.1057 51.529 44.852 52.4657 42.5007 52.4702ZM42.5007 36.0057C40.997 36.004 39.5266 36.4484 38.2757 37.2828C37.0247 38.1171 36.0494 39.3038 35.4732 40.6927C34.897 42.0816 34.7458 43.6102 35.0388 45.0851C35.3317 46.5599 36.0556 47.9147 37.1189 48.978C38.1821 50.0412 39.5369 50.7651 41.0118 51.0581C42.4866 51.351 44.0152 51.1998 45.4041 50.6236C46.793 50.0474 47.9797 49.0721 48.814 47.8211C49.6484 46.5702 50.0929 45.0998 50.0912 43.5962C50.0889 41.5837 49.2885 39.6544 47.8655 38.2314C46.4425 36.8083 44.5131 36.0079 42.5007 36.0057Z"
                fill="#0DD1C5"
            />
            <path
                d="M42.5 48.6371C41.7878 48.6364 41.0838 48.4845 40.4347 48.1914C39.7856 47.8984 39.2061 47.4708 38.7345 46.9371C38.6786 46.8747 38.6356 46.8018 38.6079 46.7227C38.5802 46.6435 38.5684 46.5597 38.5731 46.4761C38.5778 46.3924 38.599 46.3104 38.6355 46.235C38.6719 46.1595 38.7229 46.0919 38.7855 46.0361C38.9137 45.9264 39.0797 45.8712 39.2481 45.8823C39.4165 45.8934 39.5738 45.97 39.6865 46.0956C40.1924 46.6673 40.8604 47.0713 41.6016 47.2538C42.3428 47.4364 43.1221 47.3888 43.8356 47.1174C44.5491 46.846 45.163 46.3638 45.5955 45.7348C46.0281 45.1058 46.2588 44.36 46.257 43.5966C46.252 43.0656 46.1342 42.5416 45.9115 42.0595C45.6888 41.5774 45.3661 41.1481 44.965 40.8001C44.9019 40.7443 44.8504 40.6766 44.8135 40.6009C44.7766 40.5252 44.755 40.443 44.7499 40.359C44.7447 40.2749 44.7562 40.1906 44.7836 40.111C44.8111 40.0314 44.8539 39.958 44.9097 39.8949C44.9655 39.8318 45.0332 39.7804 45.1089 39.7435C45.1846 39.7066 45.2668 39.6849 45.3509 39.6798C45.435 39.6746 45.5192 39.6861 45.5988 39.7136C45.6785 39.741 45.7519 39.7838 45.815 39.8396C46.573 40.5176 47.1083 41.409 47.3505 42.3968C47.5926 43.3846 47.5303 44.4225 47.1716 45.3742C46.813 46.3259 46.1749 47.1468 45.3411 47.7292C44.5074 48.3115 43.5169 48.628 42.5 48.6371Z"
                fill="#0DD1C5"
            />
            <path
                d="M27.8375 24.4798H22.7375C22.5684 24.4798 22.4062 24.4127 22.2867 24.2931C22.1671 24.1736 22.1 24.0114 22.1 23.8423C22.1 23.6733 22.1671 23.5111 22.2867 23.3915C22.4062 23.272 22.5684 23.2048 22.7375 23.2048H27.8375C28.0066 23.2048 28.1687 23.272 28.2883 23.3915C28.4078 23.5111 28.475 23.6733 28.475 23.8423C28.475 24.0114 28.4078 24.1736 28.2883 24.2931C28.1687 24.4127 28.0066 24.4798 27.8375 24.4798Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
