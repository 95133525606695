import { ReactElement } from 'react';

export const LunchAndDinnerCruises = (): ReactElement => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M32.1475 61.446L29.861 52.3085H31.119C31.2881 52.3085 31.4502 52.2414 31.5698 52.1218C31.6893 52.0023 31.7565 51.8401 31.7565 51.671C31.7565 51.502 31.6893 51.3398 31.5698 51.2203C31.4502 51.1007 31.2881 51.0335 31.119 51.0335H23.197C22.957 51.0313 22.7199 50.9815 22.4994 50.8868C22.2789 50.7922 22.0794 50.6547 21.9125 50.4823C21.7457 50.3098 21.6147 50.106 21.5273 49.8825C21.4399 49.659 21.3978 49.4204 21.4035 49.1805L20.613 39.193C20.5994 39.024 20.5193 38.8672 20.3902 38.7572C20.2611 38.6472 20.0936 38.593 19.9245 38.6065C19.7554 38.6201 19.5986 38.7002 19.4886 38.8293C19.3786 38.9584 19.3244 39.126 19.338 39.295L20.1285 49.2315C20.1292 49.9162 20.3588 50.5809 20.7808 51.12C21.2028 51.6591 21.793 52.0415 22.4575 52.2065L20.1455 61.446C20.1068 61.6105 20.134 61.7836 20.2214 61.9281C20.3088 62.0727 20.4494 62.1774 20.613 62.2195C20.6636 62.2279 20.7153 62.2279 20.766 62.2195C20.9076 62.2204 21.0454 62.174 21.1578 62.0878C21.2701 62.0017 21.3506 61.8805 21.3865 61.7435L23.7495 52.2915H28.552L30.915 61.7435C30.9492 61.8798 31.028 62.0008 31.1389 62.087C31.2498 62.1733 31.3865 62.22 31.527 62.2195C31.5776 62.2279 31.6293 62.2279 31.68 62.2195C31.7616 62.1996 31.8384 62.1638 31.9061 62.1141C31.9738 62.0644 32.031 62.0018 32.0745 61.93C32.1179 61.8581 32.1467 61.7783 32.1593 61.6953C32.1718 61.6122 32.1678 61.5275 32.1475 61.446Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M64.8719 49.3001L65.6624 39.3126C65.6759 39.1435 65.6217 38.976 65.5118 38.8469C65.4018 38.7178 65.245 38.6376 65.0759 38.6241C64.9068 38.6106 64.7393 38.6648 64.6102 38.7748C64.4811 38.8848 64.4009 39.0415 64.3874 39.2106L63.5969 49.2491C63.5969 49.7256 63.4082 50.1826 63.0721 50.5203C62.736 50.858 62.2799 51.0489 61.8034 51.0511H53.8814C53.7123 51.0511 53.5502 51.1183 53.4306 51.2378C53.3111 51.3574 53.2439 51.5195 53.2439 51.6886C53.2439 51.8577 53.3111 52.0198 53.4306 52.1394C53.5502 52.2589 53.7123 52.3261 53.8814 52.3261H55.1394L52.8529 61.4636C52.8326 61.5451 52.8286 61.6298 52.8411 61.7128C52.8536 61.7959 52.8825 61.8756 52.9259 61.9475C52.9693 62.0194 53.0266 62.082 53.0943 62.1317C53.162 62.1814 53.2388 62.2172 53.3204 62.2371C53.3711 62.2455 53.4228 62.2455 53.4734 62.2371C53.6139 62.2375 53.7506 62.1909 53.8615 62.1046C53.9724 62.0183 54.0512 61.8974 54.0854 61.7611L56.4484 52.3091H61.2509L63.6139 61.7611C63.6498 61.8981 63.7302 62.0192 63.8426 62.1054C63.955 62.1916 64.0928 62.2379 64.2344 62.2371C64.2851 62.2455 64.3368 62.2455 64.3874 62.2371C64.551 62.1949 64.6916 62.0903 64.779 61.9457C64.8663 61.8011 64.8936 61.6281 64.8549 61.4636L62.5429 52.2241C63.2004 52.0639 63.786 51.6898 64.2076 51.1605C64.6292 50.6312 64.8629 49.9767 64.8719 49.3001V49.3001Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M56.1594 44.821H49.3594C49.3149 43.1497 48.66 41.5525 47.5184 40.331C46.3768 39.1095 44.8274 38.3483 43.1629 38.191V37.5535C43.1629 37.3844 43.0957 37.2223 42.9762 37.1027C42.8566 36.9832 42.6945 36.916 42.5254 36.916C42.3563 36.916 42.1942 36.9832 42.0746 37.1027C41.9551 37.2223 41.8879 37.3844 41.8879 37.5535V38.191C40.2234 38.3483 38.674 39.1095 37.5324 40.331C36.3908 41.5525 35.7359 43.1497 35.6914 44.821H28.8914C28.7223 44.821 28.5602 44.8882 28.4406 45.0077C28.3211 45.1273 28.2539 45.2894 28.2539 45.4585C28.2539 45.6276 28.3211 45.7897 28.4406 45.9093C28.5602 46.0289 28.7223 46.096 28.8914 46.096H41.9134V60.9625H39.4484C39.2793 60.9625 39.1172 61.0297 38.9976 61.1492C38.8781 61.2688 38.8109 61.4309 38.8109 61.6C38.8109 61.7691 38.8781 61.9313 38.9976 62.0508C39.1172 62.1704 39.2793 62.2375 39.4484 62.2375H45.6534C45.8225 62.2375 45.9846 62.1704 46.1042 62.0508C46.2237 61.9313 46.2909 61.7691 46.2909 61.6C46.2909 61.4309 46.2237 61.2688 46.1042 61.1492C45.9846 61.0297 45.8225 60.9625 45.6534 60.9625H43.1884V46.096H56.2104C56.3795 46.096 56.5416 46.0289 56.6612 45.9093C56.7807 45.7897 56.8479 45.6276 56.8479 45.4585C56.8479 45.2894 56.7807 45.1273 56.6612 45.0077C56.5416 44.8882 56.3795 44.821 56.2104 44.821H56.1594ZM42.4999 39.432C43.944 39.4356 45.3303 39.9993 46.3672 41.0044C47.404 42.0096 48.0105 43.3778 48.0589 44.821H36.9409C36.9893 43.3778 37.5958 42.0096 38.6326 41.0044C39.6695 39.9993 41.0558 39.4356 42.4999 39.432V39.432Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M43 29C39.72 29 37 26.36 37 23C37 19.72 39.72 17 43 17C46.36 17 49 19.72 49 23C49 26.36 46.36 29 43 29ZM43 18.2C40.36 18.2 38.2 20.36 38.2 23C38.2 25.64 40.36 27.8 43 27.8C45.64 27.8 47.8 25.64 47.8 23C47.8 20.36 45.64 18.2 43 18.2Z"
                fill="#0DD1C5"
            />
            <path
                d="M45.8814 23.6395H43.0014C42.8414 23.6395 42.6814 23.5595 42.6014 23.3995C42.4414 23.3195 42.4414 23.1595 42.4414 22.9995V20.3595C42.4414 20.0395 42.6814 19.7195 43.0814 19.7995C43.4014 19.7995 43.6414 20.0395 43.6414 20.4395V22.5195H45.8814C46.2014 22.5195 46.4414 22.7595 46.4414 23.1595C46.5214 23.3995 46.2014 23.6395 45.8814 23.6395Z"
                fill="#0DD1C5"
            />
            <path
                d="M60.5 32C60.3881 31.9999 60.279 31.9709 60.1881 31.9169C60.0971 31.8629 60.029 31.7868 59.9932 31.6991L59.8718 31.4159C59.5406 30.6118 58.9597 29.8936 58.1871 29.3333C58.1285 29.2923 58.0814 29.2413 58.0491 29.1837C58.0167 29.1262 58 29.0635 58 29C58 28.9365 58.0167 28.8738 58.0491 28.8163C58.0814 28.7587 58.1285 28.7076 58.1871 28.6667C58.9597 28.1064 59.5406 27.3882 59.8718 26.5841L59.9932 26.3009C60.029 26.2132 60.0971 26.1371 60.1881 26.0831C60.279 26.0291 60.3881 26.0001 60.5 26V26C60.6119 26.0001 60.721 26.0291 60.8119 26.0831C60.9029 26.1371 60.971 26.2132 61.0068 26.3009L61.121 26.5841C61.4575 27.3875 62.0405 28.1051 62.8129 28.6667C62.8715 28.7076 62.9186 28.7587 62.9509 28.8163C62.9833 28.8738 63 28.9365 63 29C63 29.0635 62.9833 29.1262 62.9509 29.1837C62.9186 29.2413 62.8715 29.2923 62.8129 29.3333C62.0405 29.8949 61.4575 30.6125 61.121 31.4159L61.0068 31.6991C60.971 31.7868 60.9029 31.8629 60.8119 31.9169C60.721 31.9709 60.6119 31.9999 60.5 32V32ZM59.315 28.997C59.7904 29.4063 60.1897 29.8715 60.5 30.3776C60.8103 29.8715 61.2096 29.4063 61.685 28.997C61.2081 28.591 60.8086 28.1275 60.5 27.6224C60.1914 28.1275 59.7919 28.591 59.315 28.997Z"
                fill="#0DD1C5"
            />
            <path
                d="M25.5 32C25.3881 31.9999 25.279 31.9709 25.1881 31.9169C25.0971 31.8629 25.029 31.7868 24.9932 31.6991L24.8718 31.4159C24.5406 30.6118 23.9597 29.8936 23.1871 29.3333C23.1285 29.2923 23.0814 29.2413 23.0491 29.1837C23.0167 29.1262 23 29.0635 23 29C23 28.9365 23.0167 28.8738 23.0491 28.8163C23.0814 28.7587 23.1285 28.7076 23.1871 28.6667C23.9597 28.1064 24.5406 27.3882 24.8718 26.5841L24.9932 26.3009C25.029 26.2132 25.0971 26.1371 25.1881 26.0831C25.279 26.0291 25.3881 26.0001 25.5 26V26C25.6119 26.0001 25.721 26.0291 25.8119 26.0831C25.9029 26.1371 25.971 26.2132 26.0068 26.3009L26.121 26.5841C26.4575 27.3875 27.0405 28.1051 27.8129 28.6667C27.8715 28.7076 27.9186 28.7587 27.9509 28.8163C27.9833 28.8738 28 28.9365 28 29C28 29.0635 27.9833 29.1262 27.9509 29.1837C27.9186 29.2413 27.8715 29.2923 27.8129 29.3333C27.0405 29.8949 26.4575 30.6125 26.121 31.4159L26.0068 31.6991C25.971 31.7868 25.9029 31.8629 25.8119 31.9169C25.721 31.9709 25.6119 31.9999 25.5 32V32ZM24.315 28.997C24.7904 29.4063 25.1897 29.8715 25.5 30.3776C25.8103 29.8715 26.2096 29.4063 26.685 28.997C26.2081 28.591 25.8086 28.1275 25.5 27.6224C25.1914 28.1275 24.7919 28.591 24.315 28.997Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
